import * as React from 'react';

import { isValidString } from '@appbuckets/utils';

import useClient from './useClient';

import type { Account, UploadedDocument, NavigationProperty } from '../interfaces';


/* --------
 * Internal Types
 * -------- */
type PublicNamespace = 'profiles' | 'messages';


/* --------
 * Hook Definition
 * -------- */
export default function usePublicUrl(defaultNamespace: PublicNamespace) {

  // ----
  // Internal Hook
  // ----
  const client = useClient();


  // ----
  // Get Client Server URL
  // ----
  const baseUrl = (client as any).client.defaults.baseURL;


  // ----
  // Return a Function to build Public Url
  // ----
  return React.useCallback(
    (filename: string | null | undefined, overrideNamespace?: PublicNamespace): string | null => {
      if (isValidString(filename)) {
        return `${new URL(baseUrl).origin}/media/uploads/${overrideNamespace || defaultNamespace}/${filename}`;
      }

      return null;
    },
    [ defaultNamespace, baseUrl ]
  );
}


/* --------
 * Side Hooks
 * -------- */
export function useProfilePhotoUrl(account: Account | null) {
  // ----
  // Internal Hook
  // ----
  const getPublicProfilePhoto = usePublicUrl('profiles');

  return account
    ? getPublicProfilePhoto(account.profilePhotoUrl)
    : null;
}


export function useMessageAttachmentUrl(file: NavigationProperty<UploadedDocument>) {
  // ----
  // Internal Hook
  // ----
  const getAttachmentUrl = usePublicUrl('messages');

  return file
    ? getAttachmentUrl(file.fileName)
    : null;
}
