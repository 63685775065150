import * as React from 'react';
import { base64ToBlobUrl } from '../utils';

import useClient from './useClient';
import useToastRaiser from './useToastRaiser';

import type { DocumentScope, UploadedDocument } from '../interfaces';


/* --------
 * An internal Cache of file content
 * -------- */
const fileCache = new Map<string, string>();


export default function useUploadedDocument(scope: DocumentScope) {


  // ----
  // Internal State
  // ----
  const [ isDownloading, setDownloading ] = React.useState<boolean>(false);
  const cachedFiles = React.useRef<Set<string>>(new Set<string>());


  // ----
  // Internal Hooks
  // ----
  const client = useClient();
  const toastRaiser = useToastRaiser();


  // ----
  // Internal Helpers
  // ----
  const getFileContent = React.useCallback(
    async (doc: UploadedDocument): Promise<string | null> => {
      /** Check if file is in cache */
      if (fileCache.has(doc.fileName)) {
        return fileCache.get(doc.fileName) as string;
      }
      /** Change downloading state */
      setDownloading(true);
      /** Show a notification */
      toastRaiser.info('Scaricamento del Documento');
      /** Download file content */
      const [ error, fileContent ] = await client.willGet<string>(`/documents/${doc.fileName}`, {
        params: {
          scope
        }
      });
      /** Restore state */
      setDownloading(false);
      /** Show error */
      if (error) {
        toastRaiser.error(error);
        return null;
      }
      /** Save response into cache */
      fileCache.set(doc.fileName, fileContent);
      /** Save filename in current hook cached files */
      cachedFiles.current.add(doc.fileName);
      /** Return the file content */
      return fileContent;
    },
    [ client, scope, toastRaiser ]
  );


  // ----
  // External Helpers
  // ----
  const purgeCachedFile = React.useCallback(
    () => {
      /** Get current cached set */
      const { current: currentCachedFiles } = cachedFiles;

      /** If set has any length, iterate and remove cached content */
      if (currentCachedFiles.size > 1) {
        currentCachedFiles.forEach((filename) => {
          fileCache.delete(filename);
        });
      }
    },
    []
  );


  // ----
  // Handlers
  // ----
  const handleOpenFile = React.useCallback(
    (doc: UploadedDocument) => async () => {
      /** Get file content */
      const fileContent = await getFileContent(doc);
      /** If no file content, return */
      if (!fileContent) {
        return;
      }
      /** Create the file data */
      const contentType = doc.contentType || 'application/octet-stream';
      /** Open the file into a new document */
      window.open(base64ToBlobUrl(fileContent, contentType), '_blank');
    },
    [ getFileContent ]
  );

  const handleDownloadFile = React.useCallback(
    (doc: UploadedDocument) => async () => {
      /** Create the download button */
      const downloadButton = global.document.createElement('a');
      /** Get the file content */
      const fileContent = await getFileContent(doc);
      /** If no file, return */
      if (!fileContent) {
        downloadButton.remove();
        return;
      }
      /** Set Attribute */
      downloadButton.href = `data:${doc.contentType || 'application/octet-stream'};base64,${fileContent}`;
      downloadButton.download = `${doc.name.replace(
        new RegExp(`\.${doc.extension}$`),
        ''
      )}.${doc.extension}`;
      /** Download file */
      downloadButton.click();
      downloadButton.remove();
    },
    [ getFileContent ]
  );


  // ----
  // Hook Return
  // ----
  return {
    isDownloading,
    purgeCachedFile,
    handleDownloadFile,
    handleOpenFile
  };
}
