import * as React from 'react';


export default function useDataManager<Data>() {

  // ----
  // Internal State
  // ----
  const [ currentEditing, setCurrentEditing ] = React.useState<Data | null>(null);
  const [ currentDeleting, setCurrentDeleting ] = React.useState<Data | null>(null);


  // ----
  // Base Extra Handlers
  // ----
  const purgeCurrentEditing = React.useCallback(
    () => setCurrentEditing(null),
    []
  );

  const purgeCurrentDeleting = React.useCallback(
    () => setCurrentDeleting(null),
    []
  );


  // ----
  // Handle Builder
  // ----
  const handleSetCurrentEditing = React.useCallback(
    (data: Data) => () => {
      setCurrentEditing(data);
    },
    []
  );

  const handleSetCurrentDeleting = React.useCallback(
    (data: Data) => () => {
      setCurrentDeleting(data);
    },
    []
  );


  // ----
  // Return tools
  // ----
  return {
    currentDeleting,
    currentEditing,
    setCurrentDeleting,
    setCurrentEditing,
    purgeCurrentDeleting,
    purgeCurrentEditing,
    handleSetCurrentEditing,
    handleSetCurrentDeleting
  };
}
