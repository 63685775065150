import * as React from 'react';

import Button from '@appbuckets/react-ui/Button';
import DropdownMenu from '@appbuckets/react-ui/DropdownMenu';
import Item from '@appbuckets/react-ui/Item';

import { useClient, useProfilePhotoUrl } from '../../../hooks';
import { useTeamScope, useUserScope } from '../../../hooks';

import { AppLink } from '../../index';
import type { PageComponent } from '../../index';

import CurrentTeamSelector from './atoms/CurrentTeamSelector';


const Navbar: PageComponent = (props) => {

  // ----
  // Internal Hooks
  // ----
  const client = useClient();
  const { userData } = useUserScope();
  const { currentRole } = useTeamScope();
  const profilePhotoUrl = useProfilePhotoUrl(userData);


  // ----
  // Handlers
  // ----
  const handleClientLogout = React.useCallback(
    () => client.logout(),
    [ client ]
  );


  // ----
  // Component Render
  // ----
  return (
    <nav id={'navbar'}>
      <div className={'navbar-content'}>
        <div className={'navbar-section main'}>
          <h4 className={'navbar-page-title'}>
            {props.currentRoute.route.title}
          </h4>
        </div>
        <div className={'navbar-section pull-right'}>
          <Button
            flat
            icon={{
              name     : 'bell',
              iconStyle: 'fad'
            }}
            tooltip={'Le tue Notifiche'}
          />
          <DropdownMenu
            inverted
            trigger={<Button flat icon={{ name: 'user', iconStyle: 'fad' }} />}
            items={[
              {
                key    : 0,
                as     : AppLink,
                to     : 'AccountSettings',
                content: 'Il tuo Account',
                icon   : 'cog'
              },
              {
                key    : 1,
                content: 'Logout',
                icon   : 'sign-out-alt',
                onClick: handleClientLogout
              }
            ]}
          />
        </div>
        <div className={'navbar-section'}>
          {userData && (
            <div className={'current-user'}>
              <Item
                avatar={{
                  type      : 'square',
                  appearance: 'primary',
                  content   : userData.initials,
                  image     : profilePhotoUrl
                }}
                textAlign={'right'}
                header={userData.firstName}
                content={currentRole?.name}
              />
            </div>
          )}
          <div className={'current-team'}>
            <CurrentTeamSelector />
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
