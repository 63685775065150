import * as React from 'react';

import { useQuery } from 'react-query';

import { useClientState } from '@appbuckets/react-app-client';

import type { Account, SharedObject, SharedObjectName } from '../../interfaces';
import { updateExceptionsDictionary } from '../../system/getApiError';

import { BaseUserScopeProvider } from './UserScope.context';

import type { ExceptionsDefinitionResponse, SharedObjectResponse, UserScopeContext } from './UserScopeProvider.types';


/* --------
 * Component Definition
 * -------- */
const UserScopeProvider: React.FC = (props) => {

  const { children } = props;


  // ----
  // Internal Hooks
  // ----
  const {
    userData,
    isLoaded: isClientLoaded
  } = useClientState<Account>();


  // ----
  // Shared Objects Loader
  // ----
  const sharedObjectQuery = useQuery<SharedObjectResponse>([
    'common', 'shared-objects', {
      withAccessToken: false
    }
  ], {
    enabled  : isClientLoaded,
    staleTime: 60 * 60_000
  });

  const exceptionsDefinitionQuery = useQuery<ExceptionsDefinitionResponse>([
    'common', 'exceptions', {
      withAccessToken: false
    }
  ], {
    enabled  : isClientLoaded,
    staleTime: 60 * 60_000
  });


  // ----
  // Exceptions Definition Updater
  // ----
  React.useEffect(
    () => {
      if (exceptionsDefinitionQuery.isSuccess && exceptionsDefinitionQuery.data) {
        updateExceptionsDictionary(exceptionsDefinitionQuery.data);
      }
    },
    [ exceptionsDefinitionQuery.data, exceptionsDefinitionQuery.isSuccess ]
  );


  // ----
  // Memoized Data
  // ----
  const sharedObjects: SharedObjectResponse | null = React.useMemo(
    () => {
      if (sharedObjectQuery.status !== 'success') {
        return null;
      }

      return sharedObjectQuery.data;
    },
    [ sharedObjectQuery.status, sharedObjectQuery.data ]
  );


  // ----
  // Utilities - SharedObjects
  // ----
  const getSharedObjects = React.useCallback(
    (name: SharedObjectName): SharedObject[] => {
      /** Assert sharedObjects has been loaded, and requested name exists */
      if (!sharedObjects || !Array.isArray(sharedObjects[name])) {
        return [];
      }

      return sharedObjects[name];
    },
    [ sharedObjects ]
  );


  // ----
  // Context Value
  // ----
  const { id: currentUserId = 0 } = userData || {};

  const ctxValue: UserScopeContext = React.useMemo(
    () => ({
      currentUserId,
      getSharedObjects,
      sharedObjects,
      userData
    }),
    [
      currentUserId,
      getSharedObjects,
      sharedObjects,
      userData
    ]
  );


  // ----
  // Component Render
  // ----
  return (
    <BaseUserScopeProvider value={ctxValue}>
      {children}
    </BaseUserScopeProvider>
  );

};


/* --------
 * Exports
 * -------- */
export default UserScopeProvider;
