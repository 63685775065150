import * as React from 'react';

import { EmptyContent } from '@appbuckets/react-ui';

import { useAppRouter } from '../../index';


/* --------
 * Component Definition
 * -------- */
const PageNotFound: React.FunctionComponent = () => {

  const { routeTo } = useAppRouter();

  const handleReturnClick = () => {
    routeTo('Dashboard');
  };

  return (
    <EmptyContent
      icon={'search-location'}
      header={'Pagina non Trovata'}
      content={'La pagina che stai cercando non è stata trovata'}
      button={{
        content: 'Torna alla Home',
        primary: true,
        rounded: true,
        onClick: handleReturnClick
      }}
    />
  );
};

PageNotFound.displayName = 'PageNotFound';

export default PageNotFound;
