import * as React from 'react';

import useQueryString from './useQueryString';


export default function useQueryStringValue<ValueType extends string | number | boolean>(
  field: string
): [ ValueType, (newValue: ValueType) => void ] {

  const [ currentData, setCurrentData ] = useQueryString();

  const handleChangeQueryString = React.useCallback(
    (newValue: ValueType) => setCurrentData({
      ...currentData,
      [field]: newValue
    }),
    [ currentData, field, setCurrentData ]
  );

  return [ currentData[field] as ValueType, handleChangeQueryString ];
}
