import * as React from 'react';

import Avatar from '@appbuckets/react-ui/Avatar';
import DropdownMenu from '@appbuckets/react-ui/DropdownMenu';
import Item from '@appbuckets/react-ui/Item';

import { useTeamScope } from '../../../../hooks';

import type { AccountRoleTeam } from '../../../../interfaces';


const CurrentTeamSelector: React.VoidFunctionComponent = () => {

  // ----
  // Internal Hooks
  // ----
  const {
    availableAccountRoleTeams,
    currentTeam,
    setCurrentTeamId
  } = useTeamScope();


  // ----
  // Memoized Data
  // ----
  const currentTeamAvatar = React.useMemo(
    () => currentTeam && (
      <Avatar
        type={'square'}
        appearance={'blue'}
        content={currentTeam.name.charAt(0)}
      />
    ),
    [ currentTeam ]
  );


  // ----
  // Handlers
  // ----
  const handleSetCurrentTeam = React.useCallback(
    (accountRoleTeam: AccountRoleTeam) => () => {
      setCurrentTeamId(accountRoleTeam.teamId);
    },
    [ setCurrentTeamId ]
  );


  // ----
  // Component Render
  // ----
  if (!availableAccountRoleTeams.length) {
    return currentTeamAvatar;
  }

  return (
    <DropdownMenu
      inverted
      basic={false}
      trigger={currentTeamAvatar}
      items={availableAccountRoleTeams.map((art) => (
        <Item
          appearance={'white shade'}
          key={art.teamId}
          avatar={{
            content   : art.team?.name.charAt(0),
            appearance: 'text',
            type      : 'square'
          }}
          header={art.team?.name}
          textAlign={'right'}
          onClick={handleSetCurrentTeam(art)}
        />
      ))}
    />
  );

};

CurrentTeamSelector.displayName = 'CurrentTeamSelector';

export default CurrentTeamSelector;
