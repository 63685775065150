/* --------
 * Base Reference Data
 * -------- */
export interface BaseReference {
  /** Check if reference is favorite or not */
  favorite: boolean;

  /** Reference id */
  id: number;

  /** Reference Label */
  label: string;

  /** Type of the Reference */
  type: ReferenceType;

  /** Reference value */
  value: string;
}


/* --------
 * Reference Type
 * -------- */
export enum ReferenceType {
  Phone = 0,
  Email = 1,
  Web = 2,
  Social = 3
}
