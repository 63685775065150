import type { IconName } from '@appbuckets/react-ui';


/* --------
 * Internal Types
 * -------- */
type MatchCondition = string | RegExp | (string | RegExp)[];


/* --------
 * Setup a Cache Object to save found mime type
 * -------- */
const cache = new Map<string, IconName>();


/* --------
 * Define Constant Mapping
 * -------- */
const defaultIcon: IconName = 'file-alt';
const mapping: [ IconName, MatchCondition ][] = [
  /** Any image file */
  [ 'file-image', /^image\// ],

  /** Audio files */
  [ 'file-audio', /^audio\// ],

  /** Video Files */
  [ 'file-video', /^video\// ],

  /** Documents Files */
  [ 'file-pdf', 'application/pdf' ],
  [ 'file-alt', 'text/plain' ],
  [
    'file-code',
    [
      'text/html',
      'text/javascript',
      'text/css',
      /application\/(ld\+)?json$/,
      /application\/(x-)?sh$/
    ]
  ],

  /** Archive */
  [
    'file-archive',
    [
      /^application\/x-(g?tar|xz|compress|bzip2?|g?zip)$/,
      /^application\/x-(7z|rar|zip)-compressed$/,
      /^application\/(zip|gzip|tar)$/
    ]
  ],

  /** Word */
  [
    'file-word',
    [
      /ms-?word/,
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.apple.pages'
    ]
  ],

  /** PowerPoint */
  [
    'file-powerpoint',
    [
      /ms-?powerpoint/,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.apple.keynote'
    ]
  ],

  /** Excel */
  [
    'file-excel',
    [
      /ms-?excel/,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.apple.numbers'
    ]
  ]
];


/* --------
 * Match utility function
 * -------- */
function computeMatching(contentType: string, condition: MatchCondition): boolean {
  /** Recurse function if condition is an array */
  if (Array.isArray(condition)) {
    return condition.reduce<boolean>((isValid, nextCondition) => (
      isValid || computeMatching(contentType, nextCondition)
    ), false);
  }

  /** Regex testing */
  if (condition instanceof RegExp) {
    return condition.test(contentType);
  }

  /** Equality testing */
  return contentType === condition;
}


/* --------
 * Main Function
 * -------- */
export function mimetypeToIcon(contentType?: string | null): IconName {
  /** No content type will return default icon */
  if (contentType == null) {
    return defaultIcon;
  }

  /** Check if is cached */
  if (cache.has(contentType)) {
    return cache.get(contentType)!;
  }

  /** Get the type */
  let contentTypeIcon: IconName = defaultIcon;

  mapping.forEach((item) => {
    if (computeMatching(contentType, item[1])) {
      [ contentTypeIcon ] = item;
    }
  });

  /** Save into cache */
  cache.set(contentType, contentTypeIcon);

  /** Return computed icon */
  return contentTypeIcon;
}
