import type { NavigationProperty, Nullable } from './generics';

import type { AccountRoleTeam } from './Account';
import type { LinkedTeam } from './Team';


export interface LinkedRole {
  roleId: number;

  role: NavigationProperty<Role>;
}

export interface Role extends LinkedTeam {
  /** Linked Accounts */
  accounts: AccountRoleTeam[];

  /** The role grade */
  grade: RoleGrade;

  /** Role identity key */
  id: number;

  /** The role name */
  name: string;

  /** The role description */
  description: Nullable<string>;
}


export enum RoleGrade {
  Guest = 0,
  BasicUser = 3,
  AdvancedUser = 4,
  Supervisor = 6,
  Owner = 9,
  Administrator = 10
}
