import * as React from 'react';

import sorter from '@appbuckets/sorter';

import { AppLink, useAppRouter } from '../../index';
import type { AppRoute, PageComponent } from '../../index';

import Privacy from '../../../ui/Privacy';


const Sidebar: PageComponent = (props) => {

  // ----
  // Internal Hooks
  // ----
  const appRouter = useAppRouter();


  // ----
  // Utilities
  // ----
  const getMenuElement = React.useCallback(
    (menu: 'above' | 'bottom') => {
      /** Transform routes */
      const routes = Array.from(appRouter.routes.values());

      /** Filter using menu position */
      const menuElements = routes.filter((route) => route.sidebarMenu === menu && !route.parent);

      return sorter(menuElements).orderBy('sidebarMenuOrder').sort();
    },
    [ appRouter.routes ]
  );

  const currentRouteName = props.currentRoute.route.name;
  const currentParentRouteName = props.currentRoute.route.parent?.name;

  const getActiveClass = React.useCallback(
    (route: AppRoute) => {
      if (route.name === currentRouteName || route.name === currentParentRouteName) {
        return 'active';
      }

      return '';
    },
    [ currentRouteName, currentParentRouteName ]
  );


  // ----
  // Component Render
  // ----
  return (
    <aside id={'sidebar'}>
      <div className={'sidebar-logo'}>
        <img src={'https://cdn.proedis.net/images/wework/logo/square_64.png'} alt={'we_work_sidebar_logo'} />
      </div>

      <div className={'sidebar-menu'}>
        <div className={'sidebar-menu-content'}>
          {getMenuElement('above').map((route) => {
            const innerComponent = (
              <AppLink
                key={route.path}
                to={route.name}
                className={`sidebar-menu-element ${getActiveClass(route)}`}
              >
                <div className={'sidebar-menu-element-icon'}>
                  {route.Icon}
                </div>
                <div className={'sidebar-menu-element-text'}>
                  {route.title}
                </div>
              </AppLink>
            );

            if (route.privacyProps) {
              return (
                <Privacy key={route.path} {...route.privacyProps}>
                  {innerComponent}
                </Privacy>
              );
            }

            return innerComponent;
          })}
        </div>

        <div className={'sidebar-menu-content pull-down'}>
          {getMenuElement('bottom').map((route) => {
            const innerComponent = (
              <AppLink
                key={route.path}
                to={route.name}
                className={`sidebar-menu-element ${getActiveClass(route)}`}
              >
                <div className={'sidebar-menu-element-icon'}>
                  {route.Icon}
                </div>
                <div className={'sidebar-menu-element-text'}>
                  {route.title}
                </div>
              </AppLink>
            );

            if (route.privacyProps) {
              return (
                <Privacy key={route.path} {...route.privacyProps}>
                  {innerComponent}
                </Privacy>
              );
            }

            return innerComponent;
          })}
        </div>
      </div>

    </aside>
  );
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
