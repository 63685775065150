import { Converter } from 'showdown';


export const markdownConverter = new Converter({
  tasklists           : true,
  headerLevelStart    : 3,
  simplifiedAutoLink  : true,
  simpleLineBreaks    : true,
  openLinksInNewWindow: true,
  metadata            : true
});
