import * as React from 'react';


/* --------
 * Inner Types
 * -------- */
type UseOptimisticStateResult<S> = [ S, React.Dispatch<React.SetStateAction<S>> ];


export default function useOptimisticState<S>(initialValue: S): UseOptimisticStateResult<S> {

  // ----
  // Internal State
  // ----
  const [ state, setState ] = React.useState(initialValue);


  // ----
  // Auto Refresh the State if value change
  // ----
  React.useEffect(
    () => {
      setState(initialValue);
    },
    [ initialValue ]
  );


  // ----
  // Hook Return
  // ----
  return [ state, setState ];

}
