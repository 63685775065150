import { useClient as useBaseClient } from '@appbuckets/react-app-client';
import type { Client } from '@appbuckets/react-app-client';

import type { Account } from '../interfaces/Account';
import type { ClientStorage } from '../init/ClientProvider';


export default function useClient(): Client<Account, ClientStorage> {
  /** Return current Client Instance */
  return useBaseClient() as Client<Account, ClientStorage>;
}
