import { contextBuilder } from '@appbuckets/react-ui-core';

import type { TeamScopeContext } from './TeamScopeProvider.types';


/* --------
 * Build the Context
 * -------- */
const {
  hook    : useTeamScope,
  Provider: BaseTeamScopeProvider
} = contextBuilder<TeamScopeContext>(undefined, 'TeamScopeContext');


/* --------
 * Base Export
 * -------- */
export { useTeamScope, BaseTeamScopeProvider };
