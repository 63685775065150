import * as React from 'react';

import Dropzone from '@appbuckets/react-ui/Dropzone';

import { useClient } from './index';


export function useDropzoneSingleFileUploader(uploadUrl: string) {

  const client = useClient();

  return React.useCallback(
    (configFormData?: (formData: FormData) => void) => Dropzone.processFileUpload(async (file) => {
      /** Create the formData container */
      const formData = new FormData();
      /** Set the name and the file blob */
      formData.append('name', file.name);
      formData.append('file', file.blob);
      /** Check if extra configuration for from data is required */
      if (typeof configFormData === 'function') {
        configFormData(formData);
      }
      /** Return the client request */
      return client.post(uploadUrl, { data: formData });
    }, { autoControlFileState: true, concurrency: 1 }),
    [ client, uploadUrl ]
  );

}
