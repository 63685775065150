function safeAddToMap<T>(set: Set<T[keyof T]>, object: T | T[], field: keyof T): void {
  if (Array.isArray(object)) {
    object.forEach((single) => {
      safeAddToMap(set, single, field);
    });
    return;
  }

  const value = object[field];

  if (!set.has(value) && value !== null && value !== undefined) {
    set.add(value);
  }
}

export function uniqueValues<T, O = any>(list: O[], path: string): T[] {
  const result: Set<T> = new Set<T>();

  list.forEach((item) => {
    path.split('.').reduce<any | null>((object, nextPath, index, array) => {

      const isLast = index === array.length - 1;

      if (object === null) {
        return null;
      }

      if (isLast) {
        safeAddToMap(result, object, nextPath);
        return null;
      }

      if (Array.isArray(object)) {
        const next: any[] = [];

        object.forEach((single) => {
          const nextSingle = single[nextPath];

          if (typeof next !== 'object' || next === null) {
            return;
          }

          next.push(nextSingle);
        });

        return next;
      }

      const next = object[nextPath];

      if (typeof next !== 'object' || next === null) {
        return null;
      }

      return Array.isArray(next)
        ? next
        : [ next ];


    }, item);
  });

  return Array.from(result.keys());
}
