import * as React from 'react';

import qs from 'qs';

import type { AnyObject } from '@appbuckets/react-ui-core';

import { useHistory, useLocation } from 'react-router-dom';


/* --------
 * Function Utilities
 * -------- */
function parseQueryString<Data>(search: string): Data {
  return qs.parse(search, {
    parseArrays: true
  }) as any as Data;
}

function stringifyQueryString<Data extends AnyObject>(data: Data): string {
  return qs.stringify(data, {
    arrayFormat: 'indices',
    skipNulls  : true
  });
}


export default function useQueryString<Data extends AnyObject>(): [ Data, (data: Data) => void ] {
  // ----
  // Internal Hook
  // ----
  const history = useHistory();
  const { search, pathname } = useLocation();


  const setQueryString = React.useCallback(
    (data: Data) => {
      /** Stringify Query String */
      const stringifyData = stringifyQueryString(data);

      /** Change location */
      history.replace({
        pathname,
        search: `?${stringifyData}`
      });
    },
    [ history, pathname ]
  );

  return [ parseQueryString<Data>(search.replace(/^\?/, '')), setQueryString ];
}
