import * as React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import BucketTheme from '@appbuckets/react-ui/BucketTheme';
import Toast from '@appbuckets/react-ui/Toast';

import type { PartialThemeOptions } from '@appbuckets/react-ui/BucketTheme';

import {
  NotificationContainer,
  NotificationManager,
  SmartComponentsProvider
} from '@appbuckets/react-ui-smart-components';


/* --------
 * Inject Icons
 * -------- */
import './iconLoader';


/* --------
 * Dayjs Extension
 * -------- */
dayjs.extend(customParseFormat);


/* --------
 * Build the Notification Manager
 * -------- */
export const ToastRaiser = new NotificationManager('toast-container', Toast);


/* --------
 * Set BucketTheme Default Options
 * -------- */
const bucketThemeOptions: PartialThemeOptions = {
  dayPicker: {
    months        : [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    weekdaysLong  : [
      'Domenica',
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato'
    ],
    weekdaysShort : [
      'Dom',
      'Lun',
      'Mar',
      'Mer',
      'Gio',
      'Ven',
      'Sab'
    ],
    firstDayOfWeek: 1,
    parse         : (date) => {
      /** Invalid date will return null */
      if (!date) {
        return null;
      }

      /** Date already of right instance return as is */
      if (date instanceof Date) {
        return date;
      }

      /** Number are formatted as timestamp */
      if (typeof date === 'number') {
        return dayjs(date).toDate();
      }

      /** Return custom parsed date */
      const [ dayPart, monthPart, yearPart ] = date.split('/');

      /** Assert every part has at lease 1 char */
      if (!dayPart || !dayPart.length || !monthPart || !monthPart.length || !yearPart || !yearPart.length) {
        return null;
      }

      /** Build the formatter */
      const formatString = `${'D'.repeat(dayPart.length)}/${'M'.repeat(monthPart.length)}/${'Y'.repeat(yearPart.length)}`;

      return dayjs(date, formatString).toDate();
    }
  },
  dropzone : {
    clearButton      : {
      content: 'Annulla'
    },
    editItemTool     : {
      tooltip: 'Modifica Nome'
    },
    hintTitle        : 'Carica Documenti',
    hintWhileDisabled: 'Caricamento Disabilitato',
    hintOnIdle       : 'Seleziona o Trascina Files',
    hintWhileDragging: 'Rilascia Files',
    removeItemTool   : {
      tooltip: 'Rimuovi File'
    },
    uploadButton     : {
      content: 'Carica'
    }
  },
  icon     : {
    iconStyle: 'fad'
  },
  /** Set default Panel Props */
  panelBody: {
    backgroundColor: 'white shade'
  },
  rxTable  : {
    noDataEmptyContentProps        : {
      header : 'Nessun Dato',
      content: 'Nessun Dato da Visualizzare'
    },
    noFilteredDataEmptyContentProps: {
      header : 'Nessun Dato',
      content: 'Nessun Dato da Visualizzare per i Filtri inseriti'
    }
  },
  select   : {
    placeholder: 'Seleziona...'
  }
};


/* --------
 * Build the Configuration Provider
 * -------- */
const ReactUITheme: React.FC = (props) => {

  const { children } = props;


  // ----
  // Render the Providers
  // ----
  return (
    <BucketTheme theme={bucketThemeOptions}>
      <SmartComponentsProvider
        notificationManager={ToastRaiser}
      >
        {/* Render the App */}
        {children}

        {/* Append the Toast Container */}
        <NotificationContainer
          namespace={'toast-container'}
          className={'toasts'}
          position={{
            horizontal: 'POS_CENTER',
            vertical  : 'POS_BOTTOM'
          }}
        />
      </SmartComponentsProvider>
    </BucketTheme>
  );
};

export default ReactUITheme;
