import * as React from 'react';


/* --------
 * Internal Types
 * -------- */
type UseBooleanStateReturn = [
  boolean, {
    setTrue: () => void,
    setFalse: () => void,
    toggle: () => void
  }
];


/* --------
 * Hook Definition
 * -------- */
export default function useBooleanState(initialValue?: boolean): UseBooleanStateReturn {

  // ----
  // Internal State
  // ----
  const [ currentValue, setCurrentValue ] = React.useState<boolean>(!!initialValue);


  // ----
  // Helpers
  // ----
  const setTrue = React.useCallback(
    () => setCurrentValue(true),
    []
  );

  const setFalse = React.useCallback(
    () => setCurrentValue(false),
    []
  );

  const toggle = React.useCallback(
    () => setCurrentValue((curr) => !curr),
    []
  );


  // ----
  // Hook Return
  // ----
  return [
    currentValue,
    { setTrue, setFalse, toggle }
  ];

}
