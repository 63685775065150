import * as React from 'react';

import { childrenUtils } from '@appbuckets/react-ui-core';

import { useTeamScope } from '../../hooks';
import { RoleGrade } from '../../interfaces';

import type { PrivacyProps } from './Privacy.types';


const Privacy: React.FunctionComponent<PrivacyProps> = (props) => {

  // ----
  // Get Props
  // ----
  const {
    children,
    content,
    minRole = RoleGrade.Guest,
    maxRole = RoleGrade.Administrator,
    onlyForRole,
    otherwise = null
  } = props;


  // ----
  // Get current user role
  // ----
  const { currentGrade } = useTeamScope();


  // ----
  // Fixed roles win on all other props
  // ----
  if ((onlyForRole != null && currentGrade !== onlyForRole) || currentGrade < minRole || currentGrade > maxRole) {
    return (
      <React.Fragment>
        {otherwise}
      </React.Fragment>
    );
  }


  // ----
  // Return inner component
  // ----
  return (
    <React.Fragment>
      {childrenUtils.isNil(children) ? content : children}
    </React.Fragment>
  );
};

export default Privacy;
