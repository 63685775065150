import * as React from 'react';

import { useMutation, useQueryClient } from 'react-query';
import type { MutationKey, QueryKey, UseMutationResult } from 'react-query';

import type { ClientRequestError } from '@appbuckets/react-app-client';

import useClient from './useClient';
import useOptimisticState from './useOptimisticState';
import useToastRaiser from './useToastRaiser';

import getApiError from '../system/getApiError';


/* --------
 * Inner Types
 * -------- */
interface UseOptimisticMutationConfig<TValue, TData = TValue> {
  /** Set of react query to be invalidated after the mutation will success */
  invalidateQueries?: (QueryKey)[];

  /** Set an optional mutation key */
  mutationKey?: MutationKey;

  /** The function to execute while mutating */
  onMutate: (value: TData, client: ReturnType<typeof useClient>) => any;

  /** Transform data before Mutation */
  transform?: (value: TValue) => TData;
}

type UseOptimisticMutationResult<TValue> = [
  /** Mutation Hook result, used to update data */
  UseMutationResult<unknown, ClientRequestError, TValue>,
  /** Current (or optimistically updated value) */
  TValue,
  /** Force optimistically value change */
  React.Dispatch<React.SetStateAction<TValue>>
];


export default function useOptimisticMutation<TValue, TData = TValue>(
  value: TValue, config: UseOptimisticMutationConfig<TValue, TData>
): UseOptimisticMutationResult<TValue> {

  // ----
  // Internal State
  // ----
  const previousValue = React.useRef<TValue>();
  const [ currentValue, updateCurrentValue ] = useOptimisticState(value);


  // ----
  // Internal Hooks
  // ----
  const client = useClient();
  const queryClient = useQueryClient();
  const toastRaiser = useToastRaiser();


  // ----
  // Internal Mutation
  // ----
  const optimisticUpdate = useMutation<unknown, ClientRequestError, TValue>({
    /** Set the optional key to be viewed in DevTools */
    mutationKey: config.mutationKey,
    /** Run the main mutation function */
    mutationFn: (newValue) => {
      /** Check value has to be transformed */
      const valueToSend = config.transform
        ? config.transform(newValue)
        : newValue;

      /** Send and return promise */
      return config.onMutate(valueToSend as TData, client);
    },
    /** Perform the optimistic update before the mutation function will be fired */
    onMutate: (newValue) => {
      /** Save the current state value as ref to be reused */
      previousValue.current = currentValue;
      /** Update the current value state */
      updateCurrentValue(newValue);
    },
    /** Restore previous value if mutation encounter in an error */
    onError: (error) => {
      /** Raise the error as toast notification */
      toastRaiser.error(getApiError(error));
      /** Restore the previous updated value */
      updateCurrentValue(previousValue.current!);
    },
    /** Query will be invalidating in any case of success or error of mutation function */
    onSettled: async () => {
      /** Assert query to be invalidated is a valid Array */
      if (!Array.isArray(config.invalidateQueries)) {
        return;
      }

      /** Keep only valid element into queries */
      return Promise.all(
        config.invalidateQueries
          .filter((key) => typeof key === 'string' || Array.isArray(key))
          .map((key) => queryClient.invalidateQueries(key))
      );
    }
  });


  // ----
  // Hook Return
  // ----
  return [ optimisticUpdate, currentValue, updateCurrentValue ];

}
