import dayjs from 'dayjs';


/* --------
 * Override standard JSON Serialization for Date Object
 * ---
 * Problem: axios will use JSON.stringify to convert an object to a json.
 * Nothing fancy here. The problem is that when a date object is sent to the server,
 * it will be converted to UTC and one might not necessarily want that.
 *
 * Solution: Override toJSON function of Date prototype to use DayJs
 * -------- */

// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function serializeDate() {
  return dayjs(this).toISOString();
};
