import type { NavigationProperty, Nullable } from './generics';

import type { AuditableTeamEntity } from './contracts/AuditableTeamEntity';

import type { RegistryAddress } from './RegistryAddress';
import type { RegistryReference } from './RegistryReference';
import type { RegistryType } from './RegistryType';


/* --------
 * Navigation Props for Registry
 * -------- */
export interface LinkedRegistry {
  /** The linked registry id */
  registryId: number;

  /** Registry entity */
  registry: NavigationProperty<Registry>;
}


/* --------
 * Main Registry Entity
 * -------- */
export interface Registry extends AuditableTeamEntity {
  /** Related registry Addresses */
  addresses: RegistryAddress[];

  /** All children registries */
  children: Registry[];

  /** Registry short code */
  code: Nullable<string>;

  /** The company name, only if typology is company */
  companyName: Nullable<string>;

  /** The company payoff, only if typology is company */
  companyPayoff: Nullable<string>;

  /** Registry display name */
  displayName: string;

  /** Registry display sub name */
  displaySubName: string;

  /** Related emails */
  emails: RegistryReference[];

  /** First name, only if typology is individual */
  firstName: Nullable<string>;

  /** Fiscal Code of Registry */
  fiscalCode: Nullable<string>;

  /** Main id */
  id: number;

  /** Registry initials */
  initials: string;

  /** Last name, only if typology is individual */
  lastName: Nullable<string>;

  /** Parent Registry */
  parent: NavigationProperty<Registry>;

  /** Parent Registry id */
  parentId: Nullable<number>;

  /** Primary favorite email */
  primaryEmail: Nullable<RegistryReference>;

  /** Primary favorite phone */
  primaryPhone: Nullable<RegistryReference>;

  /** Related phones */
  phones: RegistryReference[];

  /** Related socials */
  socials: RegistryReference[];

  /** Related type id */
  typeId: Nullable<number>;

  /** Registry type entity */
  type: NavigationProperty<RegistryType>;

  /** Typology of Registry */
  typology: RegistryTypology;

  /** Fiscal VAT Number */
  vatNumber: Nullable<string>;

  /** Related webs links */
  webs: RegistryReference[];
}


/* --------
 * Typology Enumeration
 * -------- */
export enum RegistryTypology {
  Individual = 0,
  Company = 1
}
