import type { NavigationProperty, Nullable } from './generics';

import type { AuditableTeamEntity } from './contracts/AuditableTeamEntity';

import type { Account } from './Account';
import type { Milestone } from './Milestone';
import type { Registry } from './Registry';
import type { UploadedDocument } from './UploadedDocument';


/* --------
 * Main Project Interface
 * -------- */
export interface Project extends AuditableTeamEntity {
  /** Project code */
  code: string;

  /** Project deadline date */
  deadlineDate: Nullable<string>;

  /** Project description */
  description: Nullable<string>;

  /** The display name */
  displayName: string;

  /** Related Documents */
  documents: UploadedDocument[];

  /** Effective Project endDate */
  endDate: Nullable<string>;

  /** Indicate whenever the Project has reached its main goal */
  goalAchieved: boolean;

  /** Main project id */
  id: number;

  /** Main Registry Entity */
  mainRegistry: NavigationProperty<Registry>;

  /** The main registry id */
  mainRegistryId: number;

  /** All project milestones */
  milestones: Milestone[];

  /** Project name */
  name: string;

  /** Project overview */
  overview: Nullable<string>;

  /** Project Parent */
  parent: NavigationProperty<Project>;

  /** All other side registries */
  sideRegistries: Registry[];

  /** All others team members */
  sideTeams: Account[];

  /** Project start date */
  startDate: Nullable<string>;

  /** Current project state */
  state: ProjectState;

  /** The team manager */
  teamManager: NavigationProperty<Account>;

  /** The team manager id */
  teamManagerId: number;

  /** Project time budget */
  timeBudget: Nullable<number>;

  /** Project type */
  type: ProjectType;

  /** Project value budget */
  valueBudget: Nullable<number>;
}


/* --------
 * Based Project Related Interface
 * -------- */
export interface LinkedProject {
  /** Project Id */
  projectId: number;

  /** Related project entity */
  project: NavigationProperty<Project>;
}

export interface MaybeLinkedProject {
  /** Project Id */
  projectId: Nullable<number>;

  /** Related project entity */
  project: NavigationProperty<Project>;
}


/* --------
 * Project type Enumeration
 * -------- */
export enum ProjectType {
  Deadline = 0,
  ValueBudget = 1,
  TimeBudget = 2,
  Service = 3
}


/* --------
 * Project State Enumeration
 * -------- */
export enum ProjectState {
  Wip = 0,
  Active = 5,
  Aborted = 8,
  Completed = 10
}
