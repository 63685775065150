import type * as React from 'react';

import type { ClientRequestError } from '@appbuckets/react-app-client';
import type { ExceptionsDefinitionResponse } from '../init/UserScopeProvider/UserScopeProvider.types';


/* --------
 * Build Dictionary
 * --
 * API_ERRORS is initially empty because the load/reload
 * of exception definitions is demanded to UserScopeProvider
 * component, using provided API to download definitions from server
 * -------- */
let API_ERRORS: ExceptionsDefinitionResponse = {};


/* --------
 * Update Dictionary Function
 * -------- */
export function updateExceptionsDictionary(data: ExceptionsDefinitionResponse) {
  API_ERRORS = data;
}


/* --------
 * Main Function
 * -------- */
export default function getApiError(
  error: any, dispatcher?: React.Dispatch<React.SetStateAction<ClientRequestError | undefined>>
): any {

  /** Get the error from dictionary */
  const translatedError = API_ERRORS[error?.error];

  /** If no translated error exists, return original */
  if (!translatedError) {
    return error;
  }

  /** If a dispatcher function exist, call it */
  if (typeof dispatcher === 'function') {
    dispatcher(translatedError);
  }

  /** Return the translated error */
  return translatedError;
}
