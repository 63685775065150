import React from 'react';
import ReactDOM from 'react-dom';


/* --------
 * Set DayJS and JSTimeAgo internalization
 * -------- */
import dayjs from 'dayjs';
import 'dayjs/locale/it';

import JavascriptTimeAgo from 'javascript-time-ago';
import it from 'javascript-time-ago/locale/it.json';


/* --------
 * Set YUP Locale
 * -------- */
import { it as yupItLocale } from 'yup-locales';
import * as Yup from 'yup';


/* --------
 * Load Styles
 * -------- */
import './assets/styles/index.scss';


/* --------
 * Import YardApp
 * -------- */
import AppProviders from './app/init';

import WeWorkApp from './app/App';


/* --------
 * Import System Customization
 * -------- */
import './app/system/customization';


/* --------
 * Configure Dependencies
 * -------- */
dayjs.locale('it');
JavascriptTimeAgo.addDefaultLocale(it);
Yup.setLocale(yupItLocale);


/* --------
 * Render the App
 * -------- */
ReactDOM.render(
  <AppProviders>
    <WeWorkApp />
  </AppProviders>,
  document.getElementById('root')
);
