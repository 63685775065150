import * as React from 'react';

import { will } from '@appbuckets/utils';

import { useQueryClient } from 'react-query';
import { useClientState, useClientStorage } from '@appbuckets/react-app-client';

import type { HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

import { useClient } from '../../hooks';

import type { Account } from '../../interfaces';
import type { ClientStorage } from '../ClientProvider';

import type { InvalidateQueryMessage } from './SignalRListener.types';


/* --------
 * Internal Helpers
 * -------- */
const couldConnect = (connection: HubConnection) => (
  connection.state !== HubConnectionState.Reconnecting
  && connection.state !== HubConnectionState.Connecting
  && connection.state !== HubConnectionState.Connected
);

const couldDisconnect = (connection: HubConnection) => (
  connection.state !== HubConnectionState.Disconnecting
  && connection.state !== HubConnectionState.Disconnected
);


// ----
// HubConnection Singleton Factory
// ----
let currentConnection: HubConnection | null = null;

function getHubConnection(url: string, accessTokenFactory: () => Promise<string>): HubConnection {
  currentConnection = currentConnection ?? new HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: accessTokenFactory,
      logger            : LogLevel.Warning
    })
    .withAutomaticReconnect()
    .build();

  return currentConnection;
}


/* --------
 * Component Definition
 * -------- */
const SignalRListener: React.VoidFunctionComponent = () => {

  // ----
  // Internal State
  // ----
  const [ hubState, setHubState ] = React.useState<HubConnectionState>(HubConnectionState.Disconnected);


  // ----
  // Internal Hooks
  // ----
  const { hasAuth, userData } = useClientState<Account>();
  const client = useClient();
  const queryClient = useQueryClient();


  // ----
  // Internal Refs
  // ----
  const reconnectingTimeoutRef = React.useRef<NodeJS.Timeout>();


  // ----
  // Internal Data
  // ----
  const currentUserId = userData?.id ?? 0;
  const [ currentTeamId ] = useClientStorage<ClientStorage, 'teamId'>('teamId');


  // ----
  // Create the HubConnection
  // ----
  const [ connection ] = React.useState<HubConnection>(() => (
    getHubConnection(`${client.baseUrl}/notifications`, client.getAccessToken.bind(client))
  ));


  // ----
  // Connection Start/Stop Methods
  // ----
  const startHubConnection = React.useCallback(
    async () => {
      /** Check if hub could connect to api server */
      if (couldConnect(connection)) {
        /** Set the Connecting State */
        setHubState(HubConnectionState.Connecting);
        /** Start the Connection */
        const [ connectionError ] = await will(connection.start());
        /** Update the State */
        setHubState(connectionError ? HubConnectionState.Disconnected : HubConnectionState.Connected);
      }
    },
    [ connection ]
  );

  const stopHubConnection = React.useCallback(
    async () => {
      /** Check if client could disconnect */
      if (couldDisconnect(connection)) {
        /** Set the Connecting State */
        setHubState(HubConnectionState.Disconnecting);
        /** Stop the Client Connection */
        const [ disconnectError ] = await will(connection.stop());
        /** Update the State */
        if (!disconnectError) {
          setHubState(HubConnectionState.Disconnected);
        }
      }
    },
    [ connection ]
  );


  // ----
  // Connection Handlers
  // ----
  const handleHubConnectionClose = React.useCallback(
    () => {
      /** On connection closed, must set the disconnected state */
      setHubState(HubConnectionState.Disconnected);
    },
    []
  );

  const handleHubConnectionReconnecting = React.useCallback(
    () => {
      /**
       * As the client state is in reconnecting state even if
       * the HubConnection is refreshing accessToken, even if
       * the main api server is restarting, must await before
       * set the new hub state
       */
      reconnectingTimeoutRef.current = setTimeout(() => {
        /** Set the reconnecting state after timeout expire */
        setHubState(HubConnectionState.Reconnecting);
      }, 5_000);
    },
    []
  );

  const handleHubConnectionReconnected = React.useCallback(
    () => {
      /** Bypass the Reconnected state */
      setHubState(HubConnectionState.Connected);
    },
    []
  );


  // ----
  // System Event Listener
  // ----
  React.useEffect(
    () => {
      /** Attach Events */
      connection.onclose(handleHubConnectionClose);
      connection.onreconnecting(handleHubConnectionReconnecting);
      connection.onreconnected(handleHubConnectionReconnected);
    },
    [ connection, handleHubConnectionClose, handleHubConnectionReconnected, handleHubConnectionReconnecting ]
  );


  // ----
  // Internal Mandatory Listener
  // ----
  React.useEffect(
    () => {
      /** Attach listener to always reload user data when requested */
      const conditionallyReloadUserData = async () => {
        if (hasAuth) {
          await client.reloadUserData();
          await queryClient.invalidateQueries([ 'users', currentUserId ]);
        }
      };

      /** Attach listener to invalidate queries */
      const invalidateQueries = async (message: InvalidateQueryMessage) => {
        /** Avoid query invalidation if request sender is current account */
        if (message.senderAccountId === currentUserId) {
          return;
        }

        await Promise.all(
          message.queries.map((query) => {
            /** Remap query to apply placeholder */
            const remappedQuery = query.map(q => {
              /** Check if it must replace placeholder */
              if (q === '{{teamId}}') {
                return currentTeamId;
              }

              if (q === '{{userId}}') {
                return currentUserId;
              }

              /** Check if q could be a number */
              if (!Number.isNaN(Number(q))) {
                return Number(q);
              }

              return q;
            });

            return queryClient.invalidateQueries(remappedQuery);
          })
        );
      };

      connection.on('ReloadUserData', conditionallyReloadUserData);
      connection.on('InvalidateQuery', invalidateQueries);

      /** Return function to remove listener on effect clear */
      return () => {
        connection.off('ReloadUserData');
        connection.off('InvalidateQuery');
      };
    },
    [ client, connection, hasAuth, currentUserId, currentTeamId, queryClient ]
  );


  // ----
  // Lifecycle Effect
  // ----
  React.useEffect(
    () => () => {
      /** Remove the timeout used to refresh state if reconnecting */
      if (reconnectingTimeoutRef.current) {
        clearTimeout(reconnectingTimeoutRef.current);
        reconnectingTimeoutRef.current = undefined;
      }
    },
    []
  );

  React.useEffect(
    () => {
      /** If current client has auth, then start the connection */
      if (hasAuth) {
        // noinspection JSIgnoredPromiseFromCall
        startHubConnection();
      }

      /** Return a function to close connection */
      return () => {
        // noinspection JSIgnoredPromiseFromCall
        stopHubConnection();
      };
    },
    [ hasAuth, connection, startHubConnection, stopHubConnection ]
  );


  // ----
  // Component Render does nothing
  // ----
  return null;

};

SignalRListener.displayName = 'SignalRListener';

export default SignalRListener;
