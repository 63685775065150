import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import store from 'store2';

import Loader from '@appbuckets/react-ui/Loader';

import { useClientState } from '@appbuckets/react-app-client';

import { getClientInstance } from '../../system/getClientInstance';

import { RoleGrade } from '../../interfaces';
import type { Account, AccountRoleTeam } from '../../interfaces';

import { BaseTeamScopeProvider } from './TeamScope.context';

import type { TeamScopeState, TeamScopeContext } from './TeamScopeProvider.types';


/* --------
 * Initial static data
 * -------- */
const TEAM_ID_STORE_KEY = 'weWork.currentTeamId';

const getInitialState = (hasAuth: boolean): TeamScopeState => {
  /** Get teamId from Storage */
  const initialTeamId: number | undefined = store.get(TEAM_ID_STORE_KEY);

  /** Return FreshState */
  return {
    currentTeamId: typeof initialTeamId === 'number' && hasAuth
      ? initialTeamId
      : 0,
    status       : 'idle'
  };
};


/* --------
 * Component Definition
 * -------- */
const TeamScopeProvider: React.FunctionComponent = (props) => {

  const { children } = props;


  // ----
  // Internal Hooks
  // ----
  const client = getClientInstance();
  const queryClient = useQueryClient();

  const { hasAuth, isLoaded: isClientLoaded, userData } = useClientState<Account>();


  // ----
  // Internal State
  // ----
  const [ isChangingTeam, setChangingTeam ] = React.useState(false);
  const [
    { currentTeamId, status },
    setState
  ] = React.useState<TeamScopeState>(() => getInitialState(hasAuth));

  const isFirstLoad = React.useRef(true);


  // ----
  // Internal Query
  // ----
  const userTeamsQuery = useQuery<AccountRoleTeam[]>([ 'users', userData?.id ?? 0, 'memberships' ], {
    enabled  : hasAuth && !!userData?.id && isClientLoaded,
    staleTime: 20_000
  });

  const teamMembersQuery = useQuery<AccountRoleTeam[]>([ 'teams', currentTeamId, 'members' ], {
    enabled  : status === 'ready' && currentTeamId !== 0,
    staleTime: 20_000
  });


  // ----
  // Helpers
  // ----
  const conditionallySetState = React.useCallback(
    (newState: TeamScopeState) => {
      if (status !== newState.status || currentTeamId !== newState.currentTeamId) {
        setState(newState);
      }
    },
    [ status, currentTeamId ]
  );


  // ----
  // Handlers
  // ----
  const handleSetCurrentTeamId = React.useCallback(
    (id: number) => {
      conditionallySetState({ status, currentTeamId: id });
    },
    [ conditionallySetState, status ]
  );


  // ----
  // Memoized Data
  // ----
  const allAccountRoleTeams = React.useMemo(
    (): AccountRoleTeam[] => (
      userTeamsQuery.status === 'success'
        ? userTeamsQuery.data
        : []
    ),
    [ userTeamsQuery.data, userTeamsQuery.status ]
  );

  const currentAccountRoleTeam = React.useMemo(
    () => allAccountRoleTeams.find(art => art.teamId === currentTeamId),
    [ currentTeamId, allAccountRoleTeams ]
  );

  const currentTeamMembers = React.useMemo(
    () => (
      teamMembersQuery.status === 'success'
        ? teamMembersQuery.data
        : []
    ),
    [ teamMembersQuery ]
  );


  const {
    role: currentRole = null,
    team: currentTeam = null
  } = currentAccountRoleTeam || {};


  // ----
  // Auto Updater
  // ----
  React.useEffect(
    () => {
      /** Assert a response has been received */
      if (userTeamsQuery.status !== 'success' || !hasAuth) {
        /** Restore the state if is in invalid status */
        conditionallySetState(getInitialState(hasAuth));
        /** Abort updating */
        return;
      }

      /** If a real currentAccountRoleTeam exists, abort process */
      if (hasAuth) {
        if (currentAccountRoleTeam) {
          /** Change the local state */
          conditionallySetState({ status: 'ready', currentTeamId: currentAccountRoleTeam.teamId });
          /** Complete process */
          return;
        }

        /** If userTeamsQuery response contains at least one team, set the new currentTeamId */
        if (userTeamsQuery.data[0]) {
          /** Change the local state */
          conditionallySetState({ status: 'ready', currentTeamId: userTeamsQuery.data[0].teamId });
          /** Complete process */
          return;
        }
      }

      /** If no team exists, assert the state is right */
      conditionallySetState({ status: 'ready', currentTeamId: 0 });
    },
    [
      hasAuth,
      conditionallySetState,
      currentAccountRoleTeam,
      userTeamsQuery.data,
      userTeamsQuery.status
    ]
  );


  // ----
  // Events
  // ----
  const onCurrentTeamIdChange = React.useCallback(
    async (newTeamId: number) => {
      /** Replace the Default Stored TeamId */
      client.setStoreKey('teamId', newTeamId);

      /** Grant a new AccessToken with new TeamId */
      if (newTeamId !== 0 && !isFirstLoad.current) {
        await client.grantNewAccessToken();
      }

      /** Replace the internal stored team id for next reload */
      store.set(TEAM_ID_STORE_KEY, newTeamId);

      /** Invalidate all queries, only after the first load */
      if (!isFirstLoad.current) {
        // await queryClient.invalidateQueries();
        await queryClient.resetQueries();
      }

      /** Update */
      isFirstLoad.current = false;
    },
    [ client, queryClient ]
  );


  // ----
  // Evaluate currentTeamId change
  // ----
  React.useEffect(
    () => {
      /** Set the is changing team only after first load */
      if (!isFirstLoad.current) {
        setChangingTeam(true);
      }

      /** Set the new TeamId */
      onCurrentTeamIdChange(currentTeamId)
        .then(() => {
          setTimeout(() => {
            setChangingTeam(false);
          }, 1_000);
        });
    },
    [ currentTeamId, onCurrentTeamIdChange ]
  );


  // ----
  // Context Builder
  // ----
  const ctxValue = React.useMemo(
    (): TeamScopeContext => ({
      allAccountRoleTeams,
      availableAccountRoleTeams: allAccountRoleTeams.filter(art => art.teamId !== currentTeamId),
      currentGrade             : currentRole?.grade ?? RoleGrade.Guest,
      currentRole,
      currentTeam,
      currentTeamId,
      setCurrentTeamId         : handleSetCurrentTeamId,
      status,
      teamMembers              : currentTeamMembers
    }),
    [
      allAccountRoleTeams,
      currentRole,
      currentTeam,
      currentTeamId,
      handleSetCurrentTeamId,
      status,
      currentTeamMembers
    ]
  );


  // ----
  // Component Render
  // ----
  if (isChangingTeam) {
    return (
      <div className={'full-page-loader'}>
        <Loader inline type={'circular dots'} appearance={'white shade'} size={'big'} />
      </div>
    );
  }

  return (
    <BaseTeamScopeProvider value={ctxValue}>
      {children}
    </BaseTeamScopeProvider>
  );

};

TeamScopeProvider.displayName = 'TeamScopeProvider';

export default TeamScopeProvider;
