import type { NavigationProperty, Nullable } from './generics';

import type { LinkedRole } from './Role';
import type { Team, LinkedTeam } from './Team';


export interface LinkedAccount {
  accountId: number;

  account: NavigationProperty<Account>;
}

export interface Account {
  /** Account birthday */
  birthday: Nullable<string>;

  /** Creation DateTime */
  createdAt: string;

  /** Account display name */
  displayName: string;

  /** Account main email */
  email: string;

  /** Datetime of email verification */
  emailVerifiedOn: Nullable<string>;

  /** Account first name */
  firstName: string;

  /** Gender */
  gender: AccountGender;

  /** Main id */
  id: number;

  /** Account initials */
  initials: string;

  /** Last name */
  lastName: string;

  /** Account phone */
  phone: Nullable<string>;

  /** Profile Photo URL */
  profilePhotoUrl: Nullable<string>;

  /** Skype Nickname */
  skypeNickname: Nullable<string>;

  /** Account username */
  username: string;
}


export interface AccountRoleTeam extends LinkedTeam, LinkedRole, LinkedAccount {
  /** Link start date */
  startDate: string;

  /** Link end date */
  disabled: boolean;

  /** Linked team */
  team: NavigationProperty<Team>;
}


export enum AccountGender {
  Male = 0,
  Female = 1,
  Unspecified = 2
}
