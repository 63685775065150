import type { NavigationProperty, Nullable } from './generics';

import type { AuditableTeamEntity } from './contracts/AuditableTeamEntity';

import type { Account } from './Account';
import type { ActivityItem } from './ActivityItem';
import type { MaybeLinkedMilestone } from './Milestone';
import type { MaybeLinkedProject } from './Project';
import type { Message } from './Message';
import type { UploadedDocument } from './UploadedDocument';


/* --------
 * Main Activity Interface
 * -------- */
export interface Activity extends AuditableTeamEntity, MaybeLinkedProject, MaybeLinkedMilestone {
  /** Activity assigners list */
  assigners: Account[];

  /** Related Comments */
  comments: Message[];

  /** Activity Creator */
  creator: NavigationProperty<Account>;

  /** Activity Linked Documents */
  documents: UploadedDocument[];

  /** Check activity has been done */
  done: boolean;

  /** Activity EndDate */
  endDate: Nullable<string>;

  /** Primary entity id */
  id: number;

  /** Child items */
  items: ActivityItem[];

  /** Activity Name */
  name: string;

  /** Activity Overview */
  overview: Nullable<string>;

  /** Current ActivityPriority */
  priority: ActivityPriority;

  /** The task order */
  rowOrder: number;

  /** Activity StartDate */
  startDate: Nullable<string>;

  /** Current ActivityStatus */
  status: ActivityStatus;
}


/* --------
 * Activity Priority Enumeration
 * -------- */
export enum ActivityPriority {
  Unset = 0,
  Low = 1,
  Normal = 2,
  High = 3,
  Urgent = 4
}


/* --------
 * Activity Status Enumeration
 * -------- */
export enum ActivityStatus {
  Aborted = -1,
  New = 0,
  InProgress = 1,
  OnHold = 2,
  Testing = 3,
  AwaitingFeedback = 4
}
