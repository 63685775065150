import * as React from 'react';

import Icon from '@appbuckets/react-ui/Icon';

import { RoleGrade } from '../interfaces';

import type { AppRoute } from './index';


/* --------
 * Shared Route Props
 * -------- */
const sharedPublicRoutesProps: Partial<AppRoute> = {
  hasSidebar: false,
  hasNavbar : false,
  isPrivate : false,
  isPublic  : true,
  exact     : true
};

const sharedPrivateRoutesProps: Partial<AppRoute> = {
  hasSidebar: true,
  hasNavbar : true,
  isPrivate : true,
  isPublic  : false,
  exact     : true
};


/* --------
 * Define App Main Routes
 * -------- */
const routes: AppRoute[] = [

  {
    ...sharedPublicRoutesProps,
    name     : 'Login',
    path     : `${process.env.PUBLIC_URL}/login`,
    isDefault: 'public',
    component: React.lazy(() => import('../pages/Auth/Login')),
    title    : 'Entra',
    Icon     : <Icon name={'fingerprint'} />
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'Signup',
    path     : `${process.env.PUBLIC_URL}/signup`,
    isDefault: 'public',
    component: React.lazy(() => import('../pages/Auth/Signup')),
    title    : 'Registrati',
    Icon     : <Icon name={'fingerprint'} />
  },

  {
    ...sharedPrivateRoutesProps,
    hasNavbar : false,
    hasSidebar: false,
    name      : 'CreateFirstAccountTeam',
    path      : `${process.env.PUBLIC_URL}/create-your-team`,
    component : React.lazy(() => import('../pages/Auth/CreateTeam')),
    title     : 'Crea il tuo Team',
    Icon      : <Icon name={'hand-spock'} />
  },

  {
    ...sharedPrivateRoutesProps,
    name      : 'VerifyEmail',
    hasNavbar : false,
    hasSidebar: false,
    path      : `${process.env.PUBLIC_URL}/email-verification`,
    component : React.lazy(() => import('../pages/Auth/VerifyEmail')),
    title     : 'Verifica Email',
    Icon      : <Icon name={'hand-spock'} />
  },

  {
    ...sharedPublicRoutesProps,
    isPrivate : true,
    hasNavbar : false,
    hasSidebar: false,
    name      : 'VerifyMe',
    path      : `${process.env.PUBLIC_URL}/verify-me`,
    component : React.lazy(() => import('../pages/Auth/VerifyMe')),
    title     : 'Verifica Email',
    Icon      : <Icon name={'hand-spock'} />
  },

  {
    ...sharedPublicRoutesProps,
    isPrivate : true,
    hasNavbar : false,
    hasSidebar: false,
    name      : 'AcceptInvitation',
    path      : `${process.env.PUBLIC_URL}/accept-invitation`,
    component : React.lazy(() => import('../pages/Auth/AcceptInvitation')),
    title     : 'Invito',
    Icon      : <Icon name={'hand-spock'} />
  },

  {
    ...sharedPrivateRoutesProps,
    name            : 'Dashboard',
    path            : `${process.env.PUBLIC_URL}/`,
    isDefault       : 'private',
    component       : React.lazy(() => import('../pages/Home')),
    title           : 'Dashboard',
    Icon            : <Icon name={'tachometer-alt'} />,
    sidebarMenu     : 'above',
    sidebarMenuOrder: 1
  },

  {
    ...sharedPrivateRoutesProps,
    name            : 'Registries',
    path            : `${process.env.PUBLIC_URL}/registries`,
    component       : React.lazy(() => import('../pages/Registries/All')),
    title           : 'Anagrafiche',
    Icon            : <Icon name={'address-book'} />,
    sidebarMenu     : 'above',
    sidebarMenuOrder: 2,
    routes          : [
      {
        name     : 'RegistrySingle',
        path     : '/:id',
        component: React.lazy(() => import('../pages/Registries/Single'))
      }
    ]
  },

  {
    ...sharedPrivateRoutesProps,
    name            : 'Projects',
    path            : `${process.env.PUBLIC_URL}/projects`,
    component       : React.lazy(() => import('../pages/Projects/All')),
    title           : 'Progetti',
    Icon            : <Icon name={'project-diagram'} />,
    sidebarMenu     : 'above',
    sidebarMenuOrder: 3,
    routes          : [
      {
        name     : 'ProjectSingle',
        path     : '/:id',
        component: React.lazy(() => import('../pages/Projects/Single'))
      }
    ]
  },

  {
    ...sharedPrivateRoutesProps,
    name            : 'Activities',
    path            : `${process.env.PUBLIC_URL}/activities`,
    component       : React.lazy(() => import('../pages/Activities/Board')),
    title           : 'Tasks',
    Icon            : <Icon name={'bars-progress'} />,
    sidebarMenu     : 'above',
    sidebarMenuOrder: 4
  },

  {
    ...sharedPrivateRoutesProps,
    name            : 'Settings',
    path            : `${process.env.PUBLIC_URL}/settings`,
    component       : React.lazy(() => import('../pages/Settings/Team')),
    title           : 'Impostazioni',
    Icon            : <Icon name={'cogs'} />,
    sidebarMenu     : 'bottom',
    sidebarMenuOrder: 10,
    privacyProps    : {
      minRole: RoleGrade.Owner
    }
  },

  {
    ...sharedPrivateRoutesProps,
    name     : 'AccountSettings',
    path     : `${process.env.PUBLIC_URL}/settings/account`,
    component: React.lazy(() => import('../pages/Settings/Account')),
    title    : 'Il tuo Account',
    Icon     : <Icon name={'user-cog'} />
  }

];


export default routes;
