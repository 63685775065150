import * as React from 'react';

import { AppRouter } from '@appbuckets/app-router';
import type { IsValidRouteChecker } from '@appbuckets/app-router';

import { useClientState } from '@appbuckets/react-app-client';

import { useTeamScope } from './hooks';

import routes from './router/routes';
import * as RouteComponents from './router/components';

import type { RoutesDefinition } from './router/routes-definition';
import type { Account } from './interfaces';


const YardApp: React.VFC = () => {


  // ----
  // Get Current State
  // ----
  const { isLoaded, hasAuth, userData } = useClientState<Account>();
  const { currentTeam, status: teamStatus } = useTeamScope();

  const { emailVerifiedOn } = userData || {};


  // ----
  // Compute the isAppReady bool
  // ----
  const isAppReady: boolean = hasAuth ? teamStatus === 'ready' : isLoaded;


  // ----
  // Assert Page Redirect on User without team
  // ----
  const isValidRouteChecker: IsValidRouteChecker<RoutesDefinition, keyof RoutesDefinition> = React.useCallback(
    (route, state) => {
      /** Check app is not loading or user has no auth */
      if (state.isLoading || !isAppReady || !state.userHasAuth) {
        return null;
      }

      /** If user ha no email verified, must be redirected to email verification page */
      if (!emailVerifiedOn) {
        /** Keep the VerifyMe route */
        if (route.name === 'VerifyMe') {
          return null;
        }
        /** Else, if the route is not VerifyEmail, redirect */
        if (route.name !== 'VerifyEmail') {
          return 'VerifyEmail';
        }

        /** Abort */
        return null;
      }

      /** Redirect to Dashboard if page is invalid */
      if (route.name === 'VerifyEmail') {
        return 'Dashboard';
      }

      /** If user has no teams, must be redirected to create first team page */
      if (!currentTeam && route.name !== 'CreateFirstAccountTeam') {
        return 'CreateFirstAccountTeam';
      }

      /** Redirect to dashboard if is valid team and wrong page */
      if (currentTeam && route.name === 'CreateFirstAccountTeam') {
        return 'Dashboard';
      }

      /** Account could continue */
      return null;
    },
    [ currentTeam, isAppReady, emailVerifiedOn ]
  );


  // ----
  // Component Render
  // ----
  return (
    <AppRouter<RoutesDefinition>
      useRouteClassName
      routes={routes as any}
      defaultAppName={'WeWork'}
      hidePageWhileInitiallyLoading={true}
      hidePageWhileLoading={false}
      pageTitleWhileInitiallyLoading={'Caricamento...'}
      hasNavbar={hasAuth && !!currentTeam}
      hasSidebar={hasAuth && !!currentTeam}
      userHasAuth={hasAuth}
      isInitiallyLoading={!isAppReady}
      isValidRoute={isValidRouteChecker}
      Components={{
        InitialLoader: RouteComponents.FullPageLoader,
        Navbar       : RouteComponents.Navbar as any,
        NotFound     : RouteComponents.PageNotFound,
        Sidebar      : RouteComponents.Sidebar as any
      }}
    />
  );

};

YardApp.displayName = 'YardApp';

export default YardApp;
