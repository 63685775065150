import * as React from 'react';

import ClientProvider from './ClientProvider';
import ReactQueryProvider from './ReactQueryProvider';
import ReactUITheme from './ReactUITheme';
import SignalRListener from './SignalRListener';
import TeamScopeProvider from './TeamScopeProvider';
import UserScopeProvider from './UserScopeProvider';


/* --------
 * Compress all Provider into Unique Initializer
 * -------- */
const AppProviders: React.FunctionComponent = (props) => (
  <ClientProvider>
    <ReactQueryProvider>
      <UserScopeProvider>
        <TeamScopeProvider>
          <SignalRListener />
          <ReactUITheme>
            {props.children}
          </ReactUITheme>
        </TeamScopeProvider>
      </UserScopeProvider>
    </ReactQueryProvider>
  </ClientProvider>
);

AppProviders.displayName = 'AppProviders';

export default AppProviders;
