import * as React from 'react';

import {
  useCurrentRoute as useNativeCurrentRoute,
  useAppRouterState as useNativeAppRouterState,
  useAppRouter as useNativeAppRouter,
  AppLink as NativeAppLink
} from '@appbuckets/app-router';

import type { AppLinkProps } from '@appbuckets/app-router';
import type {
  AppRouterContext as NativeAppRouterContext
} from '@appbuckets/app-router/dist/types/Router/AppRouter.context';
import type {
  AppRoute as NativeAppRoute,
  CurrentRoute as NativeCurrentRoute,
  PageComponentProps as NativePageComponentProps
} from '@appbuckets/app-router/dist/types/interfaces';

import type { PrivacyProps } from '../ui/Privacy';

import type { RoutesDefinition } from './routes-definition';


/* --------
 * Route Extension
 * -------- */
type ExtraRouteProps = {
  /** The Module Description */
  description?: React.ReactNode;

  /** The Main Module Icon */
  Icon: React.ReactNode;

  /** Set privacy props */
  privacyProps?: PrivacyProps;

  /** Set the sidebar menu into button must be visible */
  sidebarMenu?: 'above' | 'bottom';

  /** Set the sidebar menu order */
  sidebarMenuOrder?: number;

  /** The Module Title */
  title: string;
};


/* --------
 * Redefine Types
 * -------- */
export type AppRoute<Name extends keyof RoutesDefinition = keyof RoutesDefinition> =
  & Omit<NativeAppRoute<RoutesDefinition, Name>, 'component' | 'title'>
  & { component: PageComponent<Name> }
  & ExtraRouteProps;

export type CurrentRoute<Name extends keyof RoutesDefinition> =
  Omit<NativeCurrentRoute<RoutesDefinition, Name>, 'route'>
  & { route: Readonly<AppRoute<Name>> };

export type PageComponentProps<Name extends keyof RoutesDefinition> =
  & Omit<NativePageComponentProps<RoutesDefinition, Name>, 'currentRoute'>
  & { currentRoute: CurrentRoute<Name> };

export type PageComponent<Name extends keyof RoutesDefinition = keyof RoutesDefinition> =
  & React.VoidFunctionComponent<PageComponentProps<Name>>;

type OmittedContextProps = 'currentRoute' | 'defaultPrivateRoute' | 'defaultPublicRoute' | 'routes';

export type AppRouterContext<Name extends keyof RoutesDefinition> =
  & Omit<NativeAppRouterContext<RoutesDefinition, Name>, OmittedContextProps>
  &
  {
    currentRoute: CurrentRoute<Name>,
    defaultPrivateRoute: AppRoute,
    defaultPublicRoute: AppRoute,
    routes: Map<string, AppRoute>
  };


/* --------
 * Redeclare Hooks Function, adding Routes Name
 * -------- */
export function AppLink<RouteName extends keyof RoutesDefinition>(
  appLinkProps: React.PropsWithChildren<AppLinkProps<RoutesDefinition, RouteName>>
) {

  // ----
  // Props Deconstruct
  // ----
  const {
    onClick: userDefinedOnClickHandler,
    ...restAppLinkProps
  } = appLinkProps;


  // ----
  // Handlers
  // ----
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      // Stop event Propagation
      e.stopPropagation();

      // Call user defined handler if exists
      if (typeof userDefinedOnClickHandler === 'function') {
        userDefinedOnClickHandler(e);
      }
    },
    [ userDefinedOnClickHandler ]
  );

  return (
    <NativeAppLink<RoutesDefinition, RouteName>
      {...restAppLinkProps}
      onClick={handleClick}
    />
  );
}

export function useAppRouter<RouteName extends keyof RoutesDefinition>() {
  return useNativeAppRouter<RoutesDefinition, RouteName>() as unknown as AppRouterContext<RouteName>;
}

export function useAppRouterState() {
  return useNativeAppRouterState<RoutesDefinition>();
}

export function useCurrentRoute<RouteName extends keyof RoutesDefinition>() {
  return useNativeCurrentRoute<RoutesDefinition, RouteName>() as unknown as Readonly<CurrentRoute<RouteName>>;
}
