import dayjs from 'dayjs';
import JavascriptTimeAgo from 'javascript-time-ago';

import { formatNumber as formatNumberFactory } from '@appbuckets/formatters';


/* --------
 * Number Formatter
 * -------- */
export const formatNumber = formatNumberFactory.create({
  decimalSeparator : ',',
  flexibleDecimals : true,
  minPrecision     : 0,
  precision        : 2,
  thousandSeparator: '.'
});


/* --------
 * Currency Formatter
 * -------- */
export const formatCurrency = formatNumberFactory.create({
  decimalSeparator : ',',
  flexibleDecimals : false,
  minPrecision     : 2,
  precision        : 2,
  prefix           : '€',
  thousandSeparator: '.'
});


/* --------
 * Pluralization
 * -------- */
export const pluralize = (n: number, ifOne: string, ifOther: string) => (
  `${formatNumber(n)} ${n === 1 ? ifOne : ifOther}`
);


/* --------
 * DateTime Formatter
 * -------- */
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const DEFAULT_DATETIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;


/**
 * Return a formatted date using custom date format.
 * If no format will be provided, default one will be used
 * @param date
 * @param template
 */
export function formatDate(date?: dayjs.ConfigType, template: string = DEFAULT_DATE_FORMAT) {
  const dayObject = dayjs(date);

  if (!dayObject.isValid() || !date) {
    return '';
  }

  return dayObject.format(template);
}


/**
 * Return a formatted datetime using custom datetime format.
 * If no format will be provided, default one will be used
 * @param date
 * @param template
 */
export function formatDateTime(date?: dayjs.ConfigType, template: string = DEFAULT_DATETIME_FORMAT) {
  return formatDate(date, template);
}


/**
 * Smart format a date, showing relative day (today or yesterday) and the time.
 * If day is before yesterday will show base short date format instead
 * @param date
 */
export function smartFormatDateTime(date?: dayjs.ConfigType) {
  const dayObject = dayjs(date);

  if (!dayObject.isValid() || !date) {
    return '';
  }

  const today = dayjs();

  const formattedTime = dayObject.format('HH:mm');

  if (today.isSame(dayObject, 'day')) {
    return `Oggi alle ${formattedTime}`;
  }

  if (today.subtract(1, 'day').isSame(dayObject, 'day')) {
    return `Ieri alle ${formattedTime}`;
  }

  return `${dayObject.format('DD MMM')} alle ${formattedTime}`;
}


/* --------
 * TimeAgo Formatter
 * -------- */
interface FormatTimeAgoConfig {
  /** Set when has to switch to default format date */
  relativeUntilDaysAgo?: number;

  /** Set when has to switch to default format date */
  relativeBeforeDaysAfter?: number;
}

const timeAgoInstance: JavascriptTimeAgo | undefined = undefined;

export function formatTimeAgo(date?: dayjs.ConfigType, config?: FormatTimeAgoConfig) {

  const timeAgo = timeAgoInstance || new JavascriptTimeAgo('it-IT');

  // ----
  // Get Configuration
  // ----
  const {
    relativeUntilDaysAgo = 7,
    relativeBeforeDaysAfter = 7
  } = config || {};

  // ----
  // Convert to DayJs and assert is valid
  // ----
  const dayjsObject = dayjs(date);

  if (!dayjsObject.isValid() || !date) {
    return '';
  }

  /** Check if it must render as relative */
  const now = dayjs();
  const hasToBeStandard = now.subtract(relativeUntilDaysAgo, 'day').startOf('day').isAfter(dayjsObject)
    || now.add(relativeBeforeDaysAfter, 'day').endOf('day').isBefore(dayjsObject);

  // ----
  // Return relative data
  // ----
  return hasToBeStandard ? formatDate(dayjsObject) : timeAgo.format(dayjsObject.valueOf());
}
